import AnalyticsTable from "./analiticsTable";

//import { studentLayout, attemptLayout, studentData, attemptData } from "./DATA";

function App() {
	return (
		<AnalyticsTable></AnalyticsTable>
	);
}

export default App;
