import classes from "./ChartTotalLearners.module.css";
import { chart_learnersCompletedCount, chart_learnersInProgressCount } from "../../connections";
import Plot from "react-plotly.js";
import { useEffect, useState, useCallback } from "react";

// Define the expected API response structure
interface LearnerData {
  learners_in_progress: string;
  learners_completed: string;
}

type ApiResponse = { data: LearnerData[] }; // assuming the response has a "data" key containing an array

// Layouts
export const studentLayout: Partial<Plotly.Layout> = {
  autosize: true,
  title: {
    text: "Total Learners, By Status",
    yref: "paper",
    y: 1,
    yanchor: "bottom",
  },
  barmode: "stack",
  margin: {
    b: 20,
    l: 80,
    r: 20,
    t: 20,
    pad: 0,
  },
};

export const attemptLayout: Partial<Plotly.Layout> = {
  autosize: true,
  title: {
    text: "Final Assessment Attempts",
    yref: "paper",
    y: 1,
    yanchor: "bottom",
  },
  barmode: "stack",
  margin: {
    b: 20,
    l: 100,
    r: 10,
    t: 20,
    pad: 0,
  },
};

// Data
export const studentData: Plotly.Data[] = []; // Initialize with empty array or some initial data
export const attemptData: Plotly.Data[] = []; // Initialize with empty array or some initial data

// Component
const ChartTotalLearners = (props: {
  graphName: string;
  layout: Partial<Plotly.Layout>;
  data: Plotly.Data[];
}) => {
  const [plotlyData, setPlotlyData] = useState<Plotly.Data[]>(props.data);

  const getChartData = useCallback(async () => {
    let data: ApiResponse, data2: ApiResponse;
    switch (props.graphName) {
      case "StudentGraph":
        data = await chart_learnersCompletedCount() as ApiResponse;
        data2 = await chart_learnersInProgressCount() as ApiResponse;

        console.log("StudentGraph data", data);
        console.log("StudentGraph data2", data2);

        // Safely accessing values and converting to numbers
        const inProgressCount = Number(data2.data[0]?.learners_in_progress || 0);
        const completedCount = Number(data.data[0]?.learners_completed || 0);
console.log('inProgress', inProgressCount);
console.log('completed', completedCount);
        setPlotlyData([
          {
            x: [inProgressCount],
            y: ['students'],
            name: 'In Progress',
            orientation: 'h',
            marker: { color: 'rgba(220, 53, 69, .4)', width: 0.2 },
            type: 'bar',
          },
          {
            x: [completedCount],
            y: ['students'],
            name: 'Complete',
            orientation: 'h',
            marker: { color: 'rgba(220, 53, 69, 1)', width: 0.2 },
            type: 'bar',
          },
        ]);
        break;
        
      case "AttemptsGraph":
        data = await chart_learnersInProgressCount() as ApiResponse;
        console.log("AttemptsGraph data", data);

        // For "AttemptsGraph", you may want to fetch actual data instead of hardcoding values
        setPlotlyData([
          {
            x: [22], // You can replace this with actual data from the API
            y: ['Passing'],
            name: 'Passing Attempts',
            orientation: 'h',
            marker: { color: 'rgba(220, 53, 69, .4)', width: 0.2 },
            type: 'bar',
          },
          {
            x: [77], // Replace this with actual data
            y: ['Total'],
            name: 'Total Attempts',
            orientation: 'h',
            marker: { color: 'rgba(220, 53, 69, 1)', width: 0.2 },
            type: 'bar',
          },
        ]);
        break;
        
      default:
        break;
    }
  }, [props.graphName]);

  useEffect(() => {
    getChartData();
  }, [getChartData]);

  return (
    <Plot
      config={{ responsive: true, displayModeBar: false }}
      data={plotlyData}
      layout={props.layout}
      className={classes.chart}
    />
  );
};

export default ChartTotalLearners;
