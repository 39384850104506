import classes from "./Table.module.css";
import { useEffect, useState } from "react";
import {
  table_getPage_completionsPerLearnerData,
  table_getModuleData,
  table_getInteractionsData,
  table_getInternalLinksData,
} from "../../connections";

const Table = (Props: { tableName: string }) => {
  const [tableData, setTableData] = useState<any[]>([]);
  const [tableHeaders, setTableHeaders] = useState<string[]>([]);
  const [tableRows, setTableRows] = useState<JSX.Element[]>([]);

  // Fetch data based on the table name
  useEffect(() => {
    const getTableData = async () => {
      let data;
      switch (Props.tableName) {
        case "completions":
          data = await table_getPage_completionsPerLearnerData();
          break;
        case "module":
          data = await table_getModuleData();
          break;
        case "interactions":
          data = await table_getInteractionsData();
          break;
        case "internal":
          data = await table_getInternalLinksData();
          break;
        default:
          console.error("Invalid tableName");
          return;
      }

      // Log fetched data to verify it's being received
      console.log("Fetched Data:", data);

      if (data && data.data) {
        setTableData(data.data);
      } else {
        console.error("No data found for this table.");
      }
    };

    getTableData();
  }, [Props.tableName]);

  // Generate table headers
  useEffect(() => {
    if (tableData.length > 0) {
      const headers = Object.keys(tableData[0] || {});
      console.log("Generated Headers:", headers); // Log headers
      setTableHeaders(headers);
    } else {
      console.error("No table data available to generate headers.");
    }
  }, [tableData]);

  // Generate rows once tableHeaders and tableData are ready
  useEffect(() => {
    if (tableData.length > 0 && tableHeaders.length > 0) {
      const rows = tableData.map((row, index) => {
        const cells = tableHeaders.map((header, cellIndex) => (
          <td key={`${index}_${cellIndex}`} className={classes.center}>
            {row[header] !== null && row[header] !== undefined ? row[header] : "N/A"}
          </td>
        ));

        return (
          <tr key={index} className={classes.row}>
            {cells}
          </tr>
        );
      });

      console.log("Generated Rows:", rows); // Log rows
      setTableRows(rows);
    } else {
      console.error("Cannot generate rows without headers or table data.");
    }
  }, [tableHeaders, tableData]); // Only regenerate rows when both headers and data are ready

  return (
    <div className={classes.tableContainer}>
      <table className={classes.table} cellSpacing="0" cellPadding="0">
        <thead className={classes.tableHeader}>
          <tr className={classes.tableHeader}>
            {tableHeaders.map((header, index) => (
              <th key={index} className={classes.center}>
                {header.replace(/_/g, " ").toUpperCase()}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className={classes.tableBody}>
          {tableRows.length > 0 ? tableRows : (
            <tr>
              <td colSpan={tableHeaders.length}>No data available</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
